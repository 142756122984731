import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../config';

export default function Auth() {
    const navigate = useNavigate();

    

    const http = axios.create({
        baseURL: API_BASE_URL,
        headers: {
            "Content-type": "application/json",
            // "Authorization": token ? `Bearer ${token}` : ''
        }
    });

    return {
        http,
    }
}
