import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import { useEffect, useState } from "react";
import Footer from "./Footer";
import Header from "./Header";
import Sidebar from "./Sidebar";
import About from "../Pages/About"; // Import About component
import Dashboard from "../Pages/Dashboard";
import Profile from "../Pages/Profile";
import Fees from "../Pages/Fees";
import AcademicCalender from "../Pages/academicCalender";
import Timetable from "../Pages/Timetable";
import Attendance from "../Pages/Attendance";
import ExamResults from "../Pages/examResults";
import PastPapers from "../Pages/pastPapers";
import Categories from "../Pages/categories";
import LongQuestion from "../Pages/longQuestion";
import ShortQuestion from "../Pages/shortQuestion";
import MCQs from "../Pages/MCQs";
import AskQuestion from "../Pages/askQuestion";
import Materials from "../Pages/Material";
import Elearning from "../Pages/Elearning";
import Courses from "../Pages/Courses";
// import VideoModules from "../Pages/VideoModules";
import Spinner from "./spinner";
import Modules from "../Pages/Modules";
import VideoTopics from "../Pages/VideoTopics";
import ViewVideo from "../Pages/ViewVideo";

function MainContainer() {
  const navigate = useNavigate();
  const [token, setToken] = useState(false);
  const [isSlideNav, setIsSlideNav] = useState(false);

  useEffect(() => {
    const checkToken = async () => {
      // const token1 = await sessionStorage.getItem("token");
      const token1 = await localStorage.getItem("token");
      if (!token1) {
        setToken(false);
        navigate("/login");
      } else {
        setToken(true);
      }
    };

    checkToken();
  }, [navigate]);
  const toggleSlideNav = () => {
    setIsSlideNav(!isSlideNav);
  };

  // Session management End
  if (token) {
    return (
      <div className={`main-layout ${isSlideNav ? "slide-nav" : ""}`}>
        <Header toggleSlideNav={toggleSlideNav} />
        <Sidebar />
        <div className="page-wrapper">
          {/* Routes for MainContainer and About */}
          <Routes>
            <Route path="/spinner" element={<Spinner />} />
            <Route path="/" element={<Dashboard />} />
            <Route path="/about" element={<About />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/fees" element={<Fees />} />
            <Route path="/materials" element={<Materials />} />
            <Route path="/academiccalender" element={<AcademicCalender />} />
            <Route path="/timetable" element={<Timetable />} />
            <Route path="/attendance" element={<Attendance />} />
            <Route path="/examresults" element={<ExamResults />} />
            <Route path="/longquestions" element={<LongQuestion />} />
            <Route path="/shortquestion" element={<ShortQuestion />} />
            <Route path="/mcqs" element={<MCQs />} />
            <Route path="/pastpapers" element={<PastPapers />} />
            <Route path="/askaquestion" element={<AskQuestion />} />
            <Route path="/elearning" element={<Elearning />} />
            {/* Video Lms Part */}

            <Route
              path="/videocategories"
              element={<Categories name="Video" link="video" />}
            />
            <Route
              path="/videocourses"
              element={<Courses name="Video" link="video" />}
            />
            <Route
              path="/videomodules"
              element={<Modules name="Video" link="videotopics" />}
            />
            <Route path="/videotopics" element={<VideoTopics />} />
            <Route path="/viewvideo" element={<ViewVideo />} />

            {/* Long Question Lms Part */}
            <Route
              path="/longquestioncategories"
              element={<Categories name="Long Question" link="longquestion" />}
            />
            <Route
              path="/longquestioncourses"
              element={<Courses name="Long Question" link="longquestion" />}
            />
            <Route
              path="/longquestionmodules"
              element={<Modules name="Long Question" link="longquestions" />}
            />

            {/* Short Question Lms Part */}
            <Route
              path="/shortquestioncategories"
              element={
                <Categories name="Short Question" link="shortquestion" />
              }
            />
            <Route
              path="/shortquestioncourses"
              element={<Courses name="Short Question" link="shortquestion" />}
            />
            <Route
              path="/shortquestionmodules"
              element={<Modules name="Short Question" link="shortquestion" />}
            />

            {/* MCQs Lms Part */}
            <Route
              path="/mcqscategories"
              element={<Categories name="MCQa" link="mcqs" />}
            />
            <Route
              path="/mcqscourses"
              element={<Courses name="MCQs" link="mcqs" />}
            />
            <Route
              path="/mcqsmodules"
              element={<Modules name="MCQs" link="mcqs" />}
            />
          </Routes>
        </div>
        <Footer />
      </div>
    );
  } else {
    return <Spinner />;
  }
}

export default MainContainer;
