import React from "react";

export default function Footer() {
  const footerStyle = {
    // position: "fixed",
    bottom: 0,
    width: "100%",
    backgroundColor: "#ffffff", // You can change the background color as needed
    textAlign: "center",
    padding: "1em 0",
  };

  return (
    <footer style={footerStyle}>
      <p>
        COPYRIGHT © 2024 <a href="https://softlogictechnologies.com" target="_blank" rel="noreferrer">Soft Logic Technologies</a>
      </p>
    </footer>
  );
}
