import React, { useEffect, useState } from "react";
import ApexCharts from "react-apexcharts";
import Spinner from "../Components/spinner";
import { Link } from "react-router-dom";

export default function Dashboard() {
  const tableData = [
    {
      date: "01-07-2024",
      subject: "Math",
      description: "Reviewed algebraic equations",
      assignment: "Complete exercises on page 45",
      color: "black",
    },
    {
      date: "01-07-2024",
      subject: "Science",
      description: "Discussed the water cycle",
      assignment: "Write a summary of the water cycle",
      color: "white",
    },
    {
      date: "01-07-2024",
      subject: "History",
      description: "Learned about the Industrial Revolution",
      assignment: "Read chapter 4 and answer questions",
      color: "black",
    },
    {
      date: "01-07-2024",
      subject: "English",
      description: "Analyzed a short story",
      assignment: "Write an essay on the story’s theme",
      color: "white",
    },
    {
      date: "01-07-2024",
      subject: "Art",
      description: "Practiced drawing techniques",
      assignment: "Draw a landscape",
      color: "black",
    },
  ];

  const feesData = [
    {
      srNo: "1",
      challanNumber: "PRE2209",
      challanType: "Intallment 1",
      amount: "19,000",
      dueDate: "21-01-2024",
      paidDate: "30-02-2024",
      color: "black",
    },
    {
      srNo: "2",
      challanNumber: "PRE2210",
      challanType: "Intallment 2",
      amount: "20,000",
      dueDate: "21-01-2024",
      paidDate: "30-02-2024",
      color: "white",
    },
    {
      srNo: "3",
      challanNumber: "PRE2211",
      challanType: "Board Fees",
      amount: "2,500",
      dueDate: "21-01-2024",
      paidDate: "30-02-2024",
      color: "black",
    },
    {
      srNo: "4",
      challanNumber: "PRE2212",
      challanType: "Book Fee",
      amount: "500",
      dueDate: "21-01-2024",
      paidDate: "30-02-2024",
      color: "white",
    },
  ];

  const getRowStyle = (index) => {
    return {
      backgroundColor: index % 2 === 0 ? "#ffffff" : "#e74c3c",
      color: "white",
      padding: "10px",
      textAlign: "left",
      whiteSpace: "nowrap",
    };
  };

  const getCellStyle = {
    color: "white",
  };

  const getCell2Style = {
    color: "black",
  };

  const s1ChartData = {
    series: [
      {
        name: "",
        data: [75, 60, 50, 95, 35, 77], // Example data for Math progress
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "bar", // Set chart type to bar
      },
      plotOptions: {
        bar: {
          horizontal: false, // Set to true for horizontal bars
          columnWidth: "50%", // Adjust the width of bars
          endingShape: "rounded", // Rounded corners for bars
          colors: {
            ranges: [
              {
                from: 0,
                to: 50,
                color: "#e1251b", // Red for values 50 and below
              },
              {
                from: 51,
                to: 100,
                color: "#001a70", // Green for values above 50
              },
            ],
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      // colors: ["#001a70", "#808080", "#4CAF50"], // Default bar colors for each subject
      xaxis: {
        categories: [
          "Math",
          "English",
          "Physics",
          "Chemistry",
          "Biology",
          "Computer",
        ], // Categories
      },
      yaxis: {
        title: {
          text: "Progress (%)", // Y-axis title
        },
        min: 0,
        max: 100,
        tickAmount: 5,
      },
      legend: {
        position: "top",
      },
      title: {
        text: "Student Progress Chart",
        align: "center",
        margin: 10,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize: "18px",
          fontWeight: "bold",
          fontFamily: "Roboto",
          color: "#263238",
        },
      },
    },
  };

  const s2ChartData = {
    series: [
      {
        name: "",
        data: [4, 2, 2.5, 5, 3, 1.5, 4, 3.5, 5, 0.9, 4, 2], // Example data for Math progress
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "bar", // Set chart type to bar
      },
      plotOptions: {
        bar: {
          horizontal: false, // Set to true for horizontal bars
          columnWidth: "50%", // Adjust the width of bars
          endingShape: "rounded", // Rounded corners for bars
          colors: {
            ranges: [
              {
                from: 0,
                to: 1,
                color: "#e1251b", // Red for low values
              },
              {
                from: 1,
                to: 2,
                color: "#ff8585", // Yellow for medium values
              },
              {
                from: 2,
                to: 3,
                color: "#808080", // Green for high values
              },
              {
                from: 3,
                to: 4,
                color: "#3D5EE1", // Yellow for medium values
              },
              {
                from: 4,
                to: 5,
                color: "#001a70", // Yellow for medium values
              },
            ],
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#001a70", "#808080", "#4CAF50"], // Default bar colors for each subject
      xaxis: {
        categories: [
          "Regularity",
          "Punctuality",
          "Reading",
          "Writing",
          "Speaking",
          "Expressive",
          "Socialization",
          "Co-curricular",
          "Confidence",
          "Work Completion",
          "Obedience",
          "Responsibility",
        ], // Categories
      },
      yaxis: {
        title: {
          text: "Performance", // Y-axis title
        },
        min: 0,
        max: 5,
        tickAmount: 5,
      },
      legend: {
        position: "top",
      },
      title: {
        text: "Student Performance Chart",
        align: "center",
        margin: 10,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize: "18px",
          fontWeight: "bold",
          fontFamily: "Roboto",
          color: "#263238",
        },
      },
    },
  };

  const attendanceChartData = {
    series: [75, 20, 5], // Example data: 75% Present, 25% Absent
    options: {
      chart: {
        height: 350,
        type: "donut",
      },
      labels: ["Present", "Absent", "Leaves"],
      title: {
        text: "Attendance",
      },
      plotOptions: {
        pie: {
          donut: {
            size: "50%",
          },
          expandOnClick: true,
        },
      },
      stroke: {
        show: false,
        width: 0,
      },
      dataLabels: {
        enabled: true,
      },
      legend: {
        position: "bottom",
      },
      colors: ["#001a70", "#e1251b", "#808080"],
      fill: {
        type: "solid",
      },
    },
  };

  const resultChartData = {
    series: [75, 25], // Example data: 75% Present, 25% Absent
    options: {
      chart: {
        height: 350,
        type: "donut",
      },
      labels: ["Obtained", ""],
      title: {
        text: "Overall Results",
      },
      plotOptions: {
        pie: {
          donut: {
            size: "50%",
          },
          expandOnClick: true,
        },
      },
      stroke: {
        show: false,
        width: 0,
      },
      dataLabels: {
        enabled: true,
      },
      legend: {
        position: "bottom",
      },
      colors: ["#001a70", "#808080"],
      fill: {
        type: "solid",
      },
    },
  };

  const [showContent, setShowContent] = useState(false);

  const handleSpinnerComplete = () => {
    setShowContent(true);
  };
  const [userName, setusername] = useState();
  const [institute, setInstitute] = useState();
  const [program, setProgram] = useState();
  const [sclass, setSclass] = useState();
  useEffect(() => {
    // const userFromSession = sessionStorage.getItem('user');
    const userFromSession = localStorage.getItem("user");
    const instituteFromSession = localStorage.getItem("institute");
    const programFromSession = localStorage.getItem("program");
    const classFromSession = localStorage.getItem("sclass");
    const user = JSON.parse(userFromSession);
    const instituteF = JSON.parse(instituteFromSession);
    const program = JSON.parse(programFromSession);
    const stclass = JSON.parse(classFromSession);
    const firstName = user.FirstName;
    const lastName = user.LastName;
    const UserName = firstName + " " + lastName;
    setusername(UserName);
    setInstitute(instituteF);
    setProgram(program);
    setSclass(stclass);
    document.title = "Dashbboard | COWEB";
  }, []);
  return (
    <>
      {!showContent && <Spinner onComplete={handleSpinnerComplete} />}
      {showContent && (
        <div className="content container-fluid">
          <div className="row">
            <div className="col-md-6 col-xl-6 col-sm-6 col-12 d-flex">
              <div className="card bg-comman w-100">
                <div
                  className="card-body"
                  style={{
                    display: "flex",
                    justifyContent: "center", // Horizontal centering
                    alignItems: "center", // Vertical centering
                    height: "200px", // Example height
                    backgroundImage:
                      "linear-gradient(to bottom right, #3D5EE1, #001a70)",
                    borderRadius: "10px", // Adjust the radius value as needed
                    padding: "20px", // Example padding for spacing
                    color: "white", // Text color for contrast
                    textAlign: "left", // Center align text horizontally
                  }}
                >
                  <div className="container">
                    <Link to="/profile">
                      <div className="row">
                        <div className="col-md-12 col-xl-12">
                          <h1 style={{ color: "white" }}>{userName}</h1>
                          <h5 style={{ color: "silver" }}>{institute}</h5>
                          <h5 style={{ color: "silver" }}>
                            <i>Class:</i> {program}
                          </h5>
                          <h5 style={{ color: "silver" }}>
                            <i>Section:</i> {sclass}
                          </h5>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-lg-3 col-xl-3 col-sm-6 col-12 ">
              <div className="card card-chart">
                <div className="card-body">
                  <ApexCharts
                    options={attendanceChartData.options}
                    series={attendanceChartData.series}
                    type="donut"
                    height={350}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-3 col-lg-3 col-xl-3 col-sm-6 col-12 ">
              <div className="card card-chart">
                <div className="card-body">
                  <ApexCharts
                    options={resultChartData.options}
                    series={resultChartData.series}
                    type="donut"
                    height={350}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {/* Attendance Chart */}
            <div className="col-md-6 ">
              <div className="card">
                <div className="card-body">
                  <div className="chart">
                    <ApexCharts
                      options={s2ChartData.options}
                      series={s2ChartData.series}
                      type="bar"
                      height={350}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* Attendance Chart End */}
            {/* Result Chart */}
            <div className="col-md-6 ">
              <div className="card">
                <div className="card-body">
                  <div className="chart">
                    <ApexCharts
                      options={s1ChartData.options}
                      series={s1ChartData.series}
                      type="bar"
                      height={350}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* Result Chart End */}
          </div>
          <div className="row">
            <div className="col-xl-8 d-flex">
              <div className="card flex-fill student-space comman-shadow">
                <div className="card-header d-flex align-items-center">
                  <h5 className="card-title">Diary</h5>
                  <ul className="chart-list-out student-ellips">
                  </ul>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table star-student table-hover table-center table-borderless table-striped">
                      <thead
                        className="table"
                        style={{ backgroundColor: "#001a70" }}
                      >
                        <tr>
                          <th style={getCellStyle}> Date</th>
                          <th style={getCellStyle}> Subject</th>
                          <th style={getCellStyle}> Notes</th>
                          <th style={getCellStyle}> Homework</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableData.map((row, index) => (
                          <tr key={index} style={getRowStyle(index)}>
                            <td style={{ color: row.color }}>{row.date}</td>
                            <td style={{ color: row.color }}>{row.subject}</td>
                            <td style={{ color: row.color }}>
                              {row.description}
                            </td>
                            <td style={{ color: row.color }}>
                              {row.assignment}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="card-header d-flex align-items-center">
                  <h5 className="card-title">Fees</h5>
                  <ul className="chart-list-out student-ellips">
                  </ul>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table star-student table-hover table-center table-borderless table-striped">
                      <thead
                        className="table"
                        style={{ backgroundColor: "#001a70" }}
                      >
                        <tr>
                          <th style={getCellStyle}>Sr. No.</th>
                          <th style={getCellStyle}>Challan Number</th>
                          <th style={getCellStyle}>Challan Type</th>
                          <th style={getCellStyle}>Amount</th>
                          <th style={getCellStyle}>Due Date</th>
                          <th style={getCellStyle}>Paid Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {feesData.map((row, index) => (
                          <tr key={index} style={getRowStyle(index)}>
                            <td style={{ color: row.color }}>{row.srNo}</td>
                            <td style={{ color: row.color }}>{row.challanNumber}</td>
                            <td style={{ color: row.color }}>{row.challanType}</td>
                            <td style={{ color: row.color }}>{row.amount}</td>
                            <td style={{ color: row.color }}>{row.dueDate}</td>
                            <td style={{ color: row.color }}>{row.paidDate}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 d-flex">
              <div className="card flex-fill comman-shadow">
                <div className="card-header d-flex align-items-center">
                  <h5 className="card-title ">Time Table</h5>
                  <ul className="chart-list-out student-ellips">
                  </ul>
                </div>
                <div className="card-body">
                  <div className="teaching-card">
                    <ul className="activity-feed">
                      <li className="feed-item d-flex align-items-center">
                        <div className="dolor-activity">
                          <span className="feed-text1">
                            <a>Mathematics</a>
                          </span>
                          <ul className="teacher-date-list">
                            <li>
                              <i className="fas fa-clock me-2" />
                              08:00 am - 09:00 am (60 Minutes)
                            </li>
                          </ul>
                        </div>
                        <div className="activity-btns ms-auto">
                        </div>
                      </li>
                      <li className="feed-item d-flex align-items-center">
                        <div className="dolor-activity">
                          <span className="feed-text1">
                            <a>English</a>
                          </span>
                          <ul className="teacher-date-list">
                            <li>
                              <i className="fas fa-clock me-2" />
                              09:00 am - 10:00 am (60 Minutes)
                            </li>
                          </ul>
                        </div>
                        <div className="activity-btns ms-auto">
                        </div>
                      </li>
                      <li className="feed-item d-flex align-items-center">
                        <div className="dolor-activity">
                          <span className="feed-text1">
                            <a>Chemisty</a>
                          </span>
                          <ul className="teacher-date-list">
                            <li>
                              <i className="fas fa-clock me-2" />
                              10:00 am - 11:00 am (60 Minutes)
                            </li>
                          </ul>
                        </div>
                        <div className="activity-btns ms-auto">
                        </div>
                      </li>
                      <li className="feed-item d-flex align-items-center">
                        <div className="dolor-activity">
                          <span className="feed-text1">
                            <a>Physics</a>
                          </span>
                          <ul className="teacher-date-list">
                            <li>
                              <i className="fas fa-clock me-2" />
                              11:00 am - 12:00 am (60 Minutes)
                            </li>
                          </ul>
                        </div>
                        <div className="activity-btns ms-auto">
                        </div>
                      </li>
                      <li className="feed-item d-flex align-items-center">
                        <div className="dolor-activity">
                          <span className="feed-text1">
                            <a>Computer</a>
                          </span>
                          <ul className="teacher-date-list">
                            <li>
                              <i className="fas fa-clock me-2" />
                              12:00 am - 01:00 pm (60 Minutes)
                            </li>
                          </ul>
                        </div>
                        <div className="activity-btns ms-auto">
                        </div>
                      </li>
                      <li className="feed-item d-flex align-items-center">
                        <div className="dolor-activity">
                          <span className="feed-text1">
                            <a>Biology</a>
                          </span>
                          <ul className="teacher-date-list">
                            <li>
                              <i className="fas fa-clock me-2" />
                              01:00 pm - 02:00 pm (60 Minutes)
                            </li>
                          </ul>
                        </div>
                        <div className="activity-btns ms-auto">
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className="card-body"
                  style={{
                    display: "flex",
                    justifyContent: "center", // Horizontal centering
                    alignItems: "center", // Vertical centering
                    height: "200px", // Example height
                    backgroundImage:
                      "linear-gradient(to bottom right, #3D5EE1, #001a70)",
                    borderRadius: "10px", // Adjust the radius value as needed
                    padding: "20px", // Example padding for spacing
                    color: "white", // Text color for contrast
                    textAlign: "center", // Center align text horizontally
                  }}
                >
                  <h1 style={{ color: "white" }}>
                    Today Classes <br />
                    06
                  </h1>
                </div>
                <br />
              </div>
            </div>
          </div>
         
        </div>
      )}
    </>
  );
}
