import React, { useState } from 'react'
import Spinner from "../Components/spinner";
export default function Attendance() {
  const [showContent, setShowContent] = useState(false);

  const handleSpinnerComplete = () => {
    setShowContent(true);
  };
  return (
    <>
     {!showContent && <Spinner onComplete={handleSpinnerComplete} />}
     {showContent &&
      <div className="content container-fluid">
        <div className="col-md-12">
          <div className="card bg-white">
            <div className="card-header">
              <h5 className="card-title">Attendance</h5>
            </div>
            <div className="card-body">

              <div className="attendance-section">
                <div className="attendance-header">
                  <span className="attended">● Attended</span>
                  <span className="absent">● Absent</span>
                  <span className="leave">● Leave</span>
                </div>
                <div className="attendance-calendar">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Monday</th>
                        <th>Tuesday</th>
                        <th>Wednesday</th>
                        <th>Thursday</th>
                        <th>Friday</th>
                        <th>Saturday</th>
                        <th>Sunday</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="attended">1</td>
                        <td className="absent">2</td>
                        <td className="attended">3</td>
                        <td className="attended">4</td>
                        <td className="attended">5</td>
                        <td className="leave">6</td>
                        <td className="leave">7</td>
                      </tr>
                      <tr>
                        <td className="attended">8</td>
                        <td className="attended">9</td>
                        <td className="attended">10</td>
                        <td className="attended">11</td>
                        <td className="attended">12</td>
                        <td className="leave">13</td>
                        <td className="leave">14</td>
                      </tr>
                      <tr>
                        <td className="attended">15</td>
                        <td className="attended">16</td>
                        <td className="attended">17</td>
                        <td className="attended">18</td>
                        <td className="attended">19</td>
                        <td className="leave">20</td>
                        <td className="leave">21</td>
                      </tr>
                      <tr>
                        <td className="attended">22</td>
                        <td className="attended">23</td>
                        <td className="attended">24</td>
                        <td className="attended">25</td>
                        <td className="attended">26</td>
                        <td className="leave">27</td>
                        <td className="leave">28</td>
                      </tr>
                      <tr>
                        <td className="attended">29</td>
                        <td className="attended">30</td>
                        <td className="attended">31</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="attendance-summary">
                  <div className="total">
                    <span>Total:</span>
                    <span>Present: 154/168</span>
                    <span>Absent: 0/168</span>
                    <span>Leaves: 14/168</span>
                    <span>Percentage: 100%</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>}
    </>
  )
}
