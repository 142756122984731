import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

export default function Sidebar() {
  const location = useLocation();
  const [activeItem, setActiveItem] = useState(location.pathname);

  const handleItemClick = (path) => {
    setActiveItem(path);
  };

  return (
    <div className="sidebar" id="sidebar">
      <div className="sidebar-inner slimscroll">
        <div id="sidebar-menu" className="sidebar-menu">
          <ul>
            {/* <li className="menu-title">
              <span>Main Menu</span>
            </li> */}
            <li className={activeItem === "/" ? "active" : ""}>
              <Link to="/" onClick={() => handleItemClick("/")}>
                <i className="feather-grid" /> <span>Dashboard</span>
              </Link>
            </li>
            <li className={activeItem === "/attendance" ? "active" : ""}>
              <Link to="/attendance" onClick={() => handleItemClick("/attendance")}>
                <i className="fas fa-holly-berry" /> <span>Attendance</span>
              </Link>
            </li>
            <li className={activeItem === "/timetable" ? "active" : ""}>
              <Link to="/timetable" onClick={() => handleItemClick("/timetable")}>
                <i className="fas fa-table" /> <span>Timetable</span>
              </Link>
            </li>
            <li className={activeItem === "/examresults" ? "active" : ""}>
              <Link to="/examresults" onClick={() => handleItemClick("/examresults")}>
                <i className="fas fa-clipboard-list" /> <span>Exam Results</span>
              </Link>
            </li>
            <li className={activeItem === "/fees" ? "active" : ""}>
              <Link to="/fees" onClick={() => handleItemClick("/fees")}>
                <i className="fas fa-comment-dollar" /> <span>Fees</span>
              </Link>
            </li>
            <li className={activeItem === "/elearning" ? "active" : ""}>
              <Link to="/elearning" onClick={() => handleItemClick("/elearning")}>
                <i className="fas fa-video" /> <span>E-Learning</span>
              </Link>
            </li>
            
            {/* <li className={activeItem === "/videolectures" ? "active" : ""}>
              <Link to="/videolectures" onClick={() => handleItemClick("/videolectures")}>
                <i className="fas fa-video" /> <span>Video Lectures</span>
              </Link>
            </li>
            <li className={activeItem === "/longquestions" ? "active" : ""}>
              <Link to="/longquestions" onClick={() => handleItemClick("/longquestions")}>
                <i className="fas fa-book" /> <span>Long Questions</span>
              </Link>
            </li>
            <li className={activeItem === "/shortquestions" ? "active" : ""}>
              <Link to="/shortquestions" onClick={() => handleItemClick("/shortquestions")}>
                <i className="fas fa-newspaper" /> <span>Short Questions</span>
              </Link>
            </li>
            <li className={activeItem === "/mcqs" ? "active" : ""}>
              <Link to="/mcqs" onClick={() => handleItemClick("/mcqs")}>
                <i className="fas fa-book-reader" /> <span>MCQs</span>
              </Link>
            </li> */}
            <li className={activeItem === "/pastpapers" ? "active" : ""}>
              <Link to="/pastpapers" onClick={() => handleItemClick("/pastpapers")}>
                <i className="fas fa-file" /> <span>Past Papers</span>
              </Link>
            </li>
            <li className={activeItem === "/askaquestion" ? "active" : ""}>
              <Link to="/askaquestion" onClick={() => handleItemClick("/askaquestion")}>
                <i className="fas fa-chalkboard-teacher" /> <span>Ask a Question</span>
              </Link>
            </li>
            <li className={activeItem === "/materials" ? "active" : ""}>
              <Link to="/materials" onClick={() => handleItemClick("/materials")}>
                <i className="fas fa-calendar-day" /> <span>Materials</span>
              </Link>
            </li>
            <li className={activeItem === "/logout" ? "active" : ""}>
              <Link to="/logout" onClick={() => handleItemClick("/logout")}>
                <i className="fas fa-user" /> <span>Logout</span>
              </Link>
            </li>
            {/* <li className="submenu">
              <a href="/">
                <i className="fas fa-graduation-cap" /> <span> Students</span>{" "}
                <span className="menu-arrow" />
              </a>
              <ul>
                <li className={activeItem === "/students" ? "active" : ""}>
                  <Link to="/students" onClick={() => handleItemClick("/students")}>Student List</Link>
                </li>
                <li className={activeItem === "/student-details" ? "active" : ""}>
                  <Link to="/student-details" onClick={() => handleItemClick("/student-details")}>Student View</Link>
                </li>
                <li className={activeItem === "/add-student" ? "active" : ""}>
                  <Link to="/add-student" onClick={() => handleItemClick("/add-student")}>Student Add</Link>
                </li>
                <li className={activeItem === "/edit-student" ? "active" : ""}>
                  <Link to="/edit-student" onClick={() => handleItemClick("/edit-student")}>Student Edit</Link>
                </li>
              </ul>
            </li> */}
            {/* <li className="menu-title">
              <span>Management</span>
            </li> */}
              </ul>
        </div>
      </div>
    </div>
  );
}
