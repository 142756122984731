import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import Lms from "../Controllers/Lms"
import Spinner from '../Components/spinner';


export default function VideoTopics() {
  const styles = {
    th: {
      color: 'white',
      backgroundColor: '#001a70',
      //   textAlign: 'center',
    },
    td: {
      textAlign: 'center',
    },
  };
  const [topics, setTopics] = useState([]);
  const { http } = Lms(); // Initialize Lms controller
  const token = JSON.parse(localStorage.getItem("token")); // Get the token once
  const [loading, setLoading] = useState(true);  // Loading state for the component
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;
  // console.log(state);
  const loadTopics = () => {
    http.post("/getTopics", {
      token,
      moduleId: state.id,
    })
      .then(response => {
        if (response.status === 200) {
          // console.log('API response:', response.data);
          setTopics(response.data.data);
        } else {
          console.error("Fetching categories failed:", response.data.message);
        }
      })
      .catch(error => {
        console.error("Error during fetching categories:", error.message);
        localStorage.clear();
        navigate('/login');
      })
      .finally(() => {
        setLoading(false);
      });
  }



  useEffect(() => {
    loadTopics();
    if (!state) {
      navigate('/elearning');
  }
}, [state, navigate]);

if (!state) {
  return null; // Or return a loading spinner or message if desired
}


  return (
    <>
      {loading ?
        <>
          <Spinner />
        </> :
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-sub-header">
                  <h3 className="page-title">Video Topics</h3>

                </div>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="col-xl-12 d-flex">
              <div className="card flex-fill student-space comman-shadow">
                <div className="card-body">
                    <div className="row my-4">
                      <div className="col-12">
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th style={styles.th}>Index</th>
                              <th style={styles.th}>Topic Name</th>
                              <th style={styles.th}>Video</th>
                            </tr>
                          </thead>
                          <tbody>
                            {topics.map((topic, index) => (
                              <tr key={topic.id}>
                                <td>
                                  {index + 1}
                                </td>
                                <td>{topic.topicname}</td>
                                <td>
                                  <Link to='/viewvideo' state={{topic: topic, courseName: state.courseName}} className="btn" style={{ backgroundColor: '#e1251b', color:'white'}}>
                                  <i className="fas fa-play" />
                                  </Link>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      }
    </>
  );
}
