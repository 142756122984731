import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Lms from "../Controllers/Lms"
import Spinner from "../Components/spinner";
import { IMG_BASE_URL } from '../config';



export default function CoursesPage(props) {
  // const { categoryId } = useParams();
  const [courses, setCourses] = useState([]);
  const { http } = Lms(); // Initialize Lms controller
  const token = JSON.parse(localStorage.getItem("token")); // Get the token once
  const [loading, setLoading] = useState(true);  // Loading state for the component
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;



      const loadCourses = () => {
        http.post("/getCourses", { 
          token,
          catId: state,
         })
          .then(response => {
            if (response.status === 200) {
              // console.log('API response:', response.data);
              setCourses(response.data.data);
            } else {
              console.error("Fetching categories failed:", response.data.message);
            }
          })
          .catch(error => {
            console.error("Error during fetching categories:", error.message);
            localStorage.clear();
            navigate('/login');
          })
          .finally(() => {
            setLoading(false);
          });
      }

      useEffect(() => {
        loadCourses();
        if (!state) {
          navigate('/elearning');
      }
      }, [state, navigate]); // Empty dependency array ensures this runs only once
      
        if (!state) {
          return null; // Or return a loading spinner or message if desired
      }

  return (
    <>
     {loading ? 
        <>
       <Spinner />
        </> : 
    <div className="content container-fluid">
      <div className="page-header">
        <div className="row">
          <div className="col-sm-12">
            <div className="page-sub-header">
              <h3 className="page-title">{props.name} Courses</h3>
             
            </div>
          </div>
        </div>
      </div>
      <div className="row my-4">
      
            {courses.map((item, index) => (
              <div className="col-md-4" key={index}>
                <div className="card bg-comman w-100">
                  <Link to={`/${props.link}modules`} state={{id : item.id, courseName: item.coursename}} >
                    <div className="card-body">
                      <div className="container" align="center">
                        <div className="avatar avatar-xxl">
                          <img
                            className="avatar-img"
                            alt={item.coursename}
                            src={`${IMG_BASE_URL}/${item.imgurl}`}
                          />
                        </div>
                        <h3 className="my-2">{item.coursename}</h3>
                        {/* <p>{item.description ? item.description : "No description available"}</p> */}
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            ))}
      </div>
    </div>
    }
    </>
  );
}
