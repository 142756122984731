// import React, { useState } from 'react';
// import Spinner from "../Components/spinner";

// const styles = {
//   th: {
//     color: 'white',
//     backgroundColor: '#001a70',
//     textAlign: 'center',
//   },
//   td: {
//     textAlign: 'center',
//   },
// };

// // Sample timetable data in new JSON format
// const timetableData = [
//   {
//     day: 'monday',
//     slots: [
//       { time: '8:00 - 9:00', subject: 'Math', teacher: 'Mr. Smith', room: 'Room 101' },
//       { time: '9:00 - 10:00', subject: 'Science', teacher: 'Ms. Lee', room: 'Room 105' },
//       { time: '10:00 - 11:00', subject: 'History', teacher: 'Mrs. Davis', room: 'Room 103' },
//       { time: '11:00 - 12:00', subject: 'English', teacher: 'Ms. Johnson', room: 'Room 102' },
//       { time: '12:00 - 1:00', subject: 'Physical Education', teacher: 'Mr. White', room: 'Gym' },
//       { time: '1:00 - 2:00', subject: 'Lunch', room: 'Cafeteria' },
//       { time: '2:00 - 3:00', subject: 'Art', teacher: 'Ms. Black', room: 'Art Room' },
//     ]
//   },
//   {
//     day: 'tuesday',
//     slots: [
//       { time: '8:00 - 9:00', subject: 'English', teacher: 'Ms. Johnson', room: 'Room 102' },
//       { time: '9:00 - 10:00', subject: 'Math', teacher: 'Mr. Smith', room: 'Room 101' },
//       { time: '10:00 - 11:00', subject: 'Geography', teacher: 'Mr. Brown', room: 'Room 104' },
//       { time: '11:00 - 12:00', subject: 'Science', teacher: 'Ms. Lee', room: 'Room 105' },
//       { time: '12:00 - 1:00', subject: 'Music', teacher: 'Mr. Green', room: 'Music Room' },
//       { time: '1:00 - 2:00', subject: 'Lunch', room: 'Cafeteria' },
//       { time: '2:00 - 3:00', subject: 'Computer Science', teacher: 'Mr. Gray', room: 'Computer Lab' },
//     ]
//   },
//   {
//     day: "Wednesday",
//     slots: [
//       { time: "8:00 - 9:00", subject: "History", teacher: "Mrs. Davis", room: "Room 103" },
//       { time: "9:00 - 10:00", subject: "Math", teacher: "Mr. Smith", room: "Room 101" },
//       { time: "10:00 - 11:00", subject: "Science", teacher: "Ms. Lee", room: "Room 105" },
//       { time: "11:00 - 12:00", subject: "English", teacher: "Ms. Johnson", room: "Room 102" },
//       { time: "12:00 - 1:00", subject: "Physical Education", teacher: "Mr. White", room: "Gym" },
//       { time: "1:00 - 2:00", subject: "Lunch", room: "Cafeteria" },
//       { time: "2:00 - 3:00", subject: "Art", teacher: "Ms. Black", room: "Art Room" }
//     ]
//   },
//   {
//     day: "Thursday",
//     slots: [
//       { time: "8:00 - 9:00", subject: "Geography", teacher: "Mr. Brown", room: "Room 104" },
//       { time: "9:00 - 10:00", subject: "Math", teacher: "Mr. Smith", room: "Room 101" },
//       { time: "10:00 - 11:00", subject: "English", teacher: "Ms. Johnson", room: "Room 102" },
//       { time: "11:00 - 12:00", subject: "Science", teacher: "Ms. Lee", room: "Room 105" },
//       { time: "12:00 - 1:00", subject: "Music", teacher: "Mr. Green", room: "Music Room" },
//       { time: "1:00 - 2:00", subject: "Lunch", room: "Cafeteria" },
//       { time: "2:00 - 3:00", subject: "Computer Science", teacher: "Mr. Gray", room: "Computer Lab" }
//     ]
//   },
//   {
//     day: "Friday",
//     slots: [
//       { time: "8:00 - 9:00", subject: "Math", teacher: "Mr. Smith", room: "Room 101" },
//       { time: "9:00 - 10:00", subject: "Science", teacher: "Ms. Lee", room: "Room 105" },
//       { time: "10:00 - 11:00", subject: "History", teacher: "Mrs. Davis", room: "Room 103" },
//       { time: "11:00 - 12:00", subject: "English", teacher: "Ms. Johnson", room: "Room 102" },
//       { time: "12:00 - 1:00", subject: "Physical Education", teacher: "Mr. White", room: "Gym" },
//       { time: "1:00 - 2:00", subject: "Lunch", room: "Cafeteria" },
//       { time: "2:00 - 3:00", subject: "Art", teacher: "Ms. Black", room: "Art Room" }
//     ]
//   }
// ];

// export default function Timetable() {
//   const [showContent, setShowContent] = useState(false);

//     const handleSpinnerComplete = () => {
//       setShowContent(true);
//     };
//   // Get all unique time slots
//   const allTimeSlots = [...new Set(timetableData.flatMap(day => day.slots.map(slot => slot.time)))];
//   const [showTeacher, setShowTeacher] = useState(false);
//   const [showRoom, setShowRoom] = useState(false);

//   const handlePrint = () => {
//     const printableArea = document.getElementById('yourDivId'); // Replace 'yourDivId' with the ID of your div
//     if (printableArea) {
//         const iframe = document.createElement('iframe');
//         iframe.style.position = 'absolute';
//         iframe.style.width = '0px';
//         iframe.style.height = '0px';
//         iframe.style.border = 'none';

//         document.body.appendChild(iframe);

//         const doc = iframe.contentWindow.document;
//         const printContents = printableArea.innerHTML;

      
//         doc.open();
//         doc.write(`
//             <html>
//             <head>
//                 <style>
//                     body, html { margin: 0; padding: 0; }
//                     table { width: 100%; border-collapse: collapse; }
//                     th, td { border: 1px solid #000; padding: 8px; }
//                     .heading { text-align: center; font-size: 24px; margin-bottom: 20px; }
//                 </style>
//             </head>
//             <body>
//                 <div class="heading">Timetable</div>
//         `);
//         doc.write(printContents);
//         doc.write('</body></html>');
//         doc.close();

//         iframe.contentWindow.focus();
//         iframe.contentWindow.print();

//         setTimeout(() => {
//             document.body.removeChild(iframe);
//         }, 500);
//     } else {
//         console.error('Div not found or ID is incorrect');
//     }
// };

//   return (
//     <>
//      {!showContent && <Spinner onComplete={handleSpinnerComplete} />}
//      {showContent &&
//       <div className="content container-fluid">
//         <div className="col-md-12">
//           <div className="card bg-white">
//             <div className="card-header">
//               <div className="row">
//                 <div className="col-md-10">
//                 <h5 className="card-title">Timetable</h5>
//                 </div>
//                 <div className="col-md-2">
//               <button onClick={handlePrint} className="btn btn-primary">Print Timetable</button>
//                 </div>
//               </div>
//             </div>
//             <div className="card-body">
//               <div className="col-xl-12 d-flex">
//                 <div className="card flex-fill student-space comman-shadow">
//                   <div className="card-header d-flex align-items-center">
//                     {/* <h5 className="card-title">Time Table</h5> */}
//                     <ul className="chart-list-out student-ellips">
//                       {/* <li className="star-menus">
//                         <a href={{ javascript: void 0 }}>
//                           <i className="fas fa-ellipsis-v" />
//                         </a>
//                       </li> */}
//                     </ul>
//                   </div>
//                   <div className="card-body">
//                     <div className="table-responsive" id='yourDivId'>
//                       <table className="table star-student table-hover table-center table-borderless table-striped">
//                         <thead className="thead-light">
//                           <tr>
//                             {/* <th style={styles.th}>Time Slots</th> */}
//                             {timetableData.map((day, index) => (
//                               <th key={index} style={styles.th}>{day.day.charAt(0).toUpperCase() + day.day.slice(1)}</th>
//                             ))}
//                           </tr>
//                         </thead>
//                         <tbody>
//                           {allTimeSlots.map((time, index) => (
//                             <tr key={index}>
//                               {/* <td style={styles.td} className="table-secondary text-nowrap">
//                                 <div>{time}</div>
//                               </td> */}
//                               {timetableData.map((day, dayIndex) => {
//                                 const slot = day.slots.find(slot => slot.time === time);
//                                 return (
//                                   <td key={dayIndex} style={styles.td}>
//                                     {slot ? (
//                                       <>
//                                         {slot.time}<br />
//                                         {slot.subject}<br />
//                                         {showTeacher && <span>{slot.teacher}<br /></span>}
//                                         {showRoom && <span>{slot.room}</span>}
//                                       </>
//                                     ) : (
//                                       'Not Planned'
//                                     )}
//                                   </td>
//                                 );
//                               })}
//                             </tr>
//                           ))}
//                         </tbody>
//                       </table>
//                     </div>
//                     <div>
//                       <div className='card-body'>
//                         <div className="container">
//                           <div className="row">
//                             <div className="col-md-2">
//                               <label className="custom_check mr-2 mb-0 d-inline-flex remember-me">
//                               {" "}
//                               Show Teacher
//                               <input 
//                                 type="checkbox"
//                                 name="radio"
//                                 checked={showTeacher} 
//                                 onChange={() => setShowTeacher(!showTeacher)}
//                                 />
//                               <span className="checkmark" />
//                             </label>
//                             </div>
//                             <div className="col-md-2">
//                                 <label className="custom_check mr-2 mb-0 d-inline-flex remember-me">
//                                 {" "}
//                                 Show Room
//                                 <input 
//                                   type="checkbox"
//                                   name="radio"
//                                   checked={showRoom} 
//                                   onChange={() => setShowRoom(!showRoom)} 
//                                   />
//                                 <span className="checkmark" />
//                               </label>
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>}
//     </>
//   );
// }
import React, { useState, useEffect } from 'react';
import Spinner from "../Components/spinner";

const styles = {
  th: {
    color: 'white',
    backgroundColor: '#001a70',
    textAlign: 'center',
  },
  td: {
    textAlign: 'center',
  },
  highlighted: {
    backgroundColor: '#c7c4c4',
    // color: "white", // Highlight color
  },
};

// Sample timetable data in new JSON format
const timetableData = [
  {
    day: 'monday',
    slots: [
      { time: '8:00 - 9:00', subject: 'Math', teacher: 'Mr. Smith', room: 'Room 101' },
      { time: '9:00 - 10:00', subject: 'Science', teacher: 'Ms. Lee', room: 'Room 105' },
      { time: '10:00 - 11:00', subject: 'History', teacher: 'Mrs. Davis', room: 'Room 103' },
      { time: '11:00 - 12:00', subject: 'English', teacher: 'Ms. Johnson', room: 'Room 102' },
      { time: '12:00 - 1:00', subject: 'Physical Education', teacher: 'Mr. White', room: 'Gym' },
      { time: '1:00 - 2:00', subject: 'Lunch', room: 'Cafeteria' },
      { time: '2:00 - 3:00', subject: 'Art', teacher: 'Ms. Black', room: 'Art Room' },
    ]
  },
  {
    day: 'tuesday',
    slots: [
      { time: '8:00 - 9:00', subject: 'English', teacher: 'Ms. Johnson', room: 'Room 102' },
      { time: '9:00 - 10:00', subject: 'Math', teacher: 'Mr. Smith', room: 'Room 101' },
      { time: '10:00 - 11:00', subject: 'Geography', teacher: 'Mr. Brown', room: 'Room 104' },
      { time: '11:00 - 12:00', subject: 'Science', teacher: 'Ms. Lee', room: 'Room 105' },
      { time: '12:00 - 1:00', subject: 'Music', teacher: 'Mr. Green', room: 'Music Room' },
      { time: '1:00 - 2:00', subject: 'Lunch', room: 'Cafeteria' },
      { time: '2:00 - 3:00', subject: 'Computer Science', teacher: 'Mr. Gray', room: 'Computer Lab' },
    ]
  },
  {
        day: "Wednesday",
        slots: [
          { time: "8:00 - 9:00", subject: "History", teacher: "Mrs. Davis", room: "Room 103" },
          { time: "9:00 - 10:00", subject: "Math", teacher: "Mr. Smith", room: "Room 101" },
          { time: "10:00 - 11:00", subject: "Science", teacher: "Ms. Lee", room: "Room 105" },
          { time: "11:00 - 12:00", subject: "English", teacher: "Ms. Johnson", room: "Room 102" },
          { time: "12:00 - 1:00", subject: "Physical Education", teacher: "Mr. White", room: "Gym" },
          { time: "1:00 - 2:00", subject: "Lunch", room: "Cafeteria" },
          { time: "2:00 - 3:00", subject: "Art", teacher: "Ms. Black", room: "Art Room" }
        ]
      },
      {
        day: "Thursday",
        slots: [
          { time: "8:00 - 9:00", subject: "Geography", teacher: "Mr. Brown", room: "Room 104" },
          { time: "9:00 - 10:00", subject: "Math", teacher: "Mr. Smith", room: "Room 101" },
          { time: "10:00 - 11:00", subject: "English", teacher: "Ms. Johnson", room: "Room 102" },
          { time: "11:00 - 12:00", subject: "Science", teacher: "Ms. Lee", room: "Room 105" },
          { time: "12:00 - 1:00", subject: "Music", teacher: "Mr. Green", room: "Music Room" },
          { time: "1:00 - 2:00", subject: "Lunch", room: "Cafeteria" },
          { time: "2:00 - 3:00", subject: "Computer Science", teacher: "Mr. Gray", room: "Computer Lab" }
        ]
      },
      {
        day: "Friday",
        slots: [
          { time: "8:00 - 9:00", subject: "Math", teacher: "Mr. Smith", room: "Room 101" },
          { time: "9:00 - 10:00", subject: "Science", teacher: "Ms. Lee", room: "Room 105" },
          { time: "10:00 - 11:00", subject: "History", teacher: "Mrs. Davis", room: "Room 103" },
          { time: "11:00 - 12:00", subject: "English", teacher: "Ms. Johnson", room: "Room 102" },
          { time: "12:00 - 1:00", subject: "Physical Education", teacher: "Mr. White", room: "Gym" },
          { time: "1:00 - 2:00", subject: "Lunch", room: "Cafeteria" },
          { time: "2:00 - 3:00", subject: "Art", teacher: "Ms. Black", room: "Art Room" }
        ]
      }
  // Add other days here...
];

export default function Timetable() {
  const [showContent, setShowContent] = useState(false);
  const [showTeacher, setShowTeacher] = useState(false);
  const [showRoom, setShowRoom] = useState(false);
  const [currentDay, setCurrentDay] = useState(null);

  const handleSpinnerComplete = () => {
    setShowContent(true);
  };

  // Get all unique time slots
  const allTimeSlots = [...new Set(timetableData.flatMap(day => day.slots.map(slot => slot.time)))];

  // Determine the current day
  useEffect(() => {
    const daysOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    const today = new Date().getDay();
    setCurrentDay(daysOfWeek[today]);
  }, []);

  const handlePrint = () => {
    const printableArea = document.getElementById('yourDivId'); // Replace 'yourDivId' with the ID of your div
    if (printableArea) {
      const iframe = document.createElement('iframe');
      iframe.style.position = 'absolute';
      iframe.style.width = '0px';
      iframe.style.height = '0px';
      iframe.style.border = 'none';

      document.body.appendChild(iframe);

      const doc = iframe.contentWindow.document;
      const printContents = printableArea.innerHTML;

      doc.open();
      doc.write(`
        <html>
        <head>
          <style>
            body, html { margin: 0; padding: 0; }
            table { width: 100%; border-collapse: collapse; }
            th, td { border: 1px solid #000; padding: 8px; }
            .heading { text-align: center; font-size: 24px; margin-bottom: 20px; }
          </style>
        </head>
        <body>
          <div class="heading">Timetable</div>
      `);
      doc.write(printContents);
      doc.write('</body></html>');
      doc.close();

      iframe.contentWindow.focus();
      iframe.contentWindow.print();

      setTimeout(() => {
        document.body.removeChild(iframe);
      }, 500);
    } else {
      console.error('Div not found or ID is incorrect');
    }
  };

  return (
    <>
      {!showContent && <Spinner onComplete={handleSpinnerComplete} />}
      {showContent &&
        <div className="content container-fluid">
          <div className="col-md-12">
            <div className="card bg-white">
              <div className="card-header">
                <div className="row">
                  <div className="col-md-10">
                    <h5 className="card-title">Timetable</h5>
                  </div>
                  <div className="col-md-2">
                    <button onClick={handlePrint} className="btn btn-primary">Print Timetable</button>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="col-xl-12 d-flex">
                  <div className="card flex-fill student-space comman-shadow">
                    <div className="card-body">
                      <div className="table-responsive" id='yourDivId'>
                        <table className="table star-student table-hover table-center table-borderless table-striped">
                          <thead className="thead-light">
                            <tr>
                              {timetableData.map((day, index) => (
                                <th key={index} style={styles.th}>{day.day.charAt(0).toUpperCase() + day.day.slice(1)}</th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {allTimeSlots.map((time, index) => (
                              <tr key={index}>
                                {timetableData.map((day, dayIndex) => {
                                  const slot = day.slots.find(slot => slot.time === time);
                                  const isCurrentDay = day.day.toLowerCase() === currentDay;

                                  return (
                                    <td key={dayIndex} style={{ ...styles.td, ...(isCurrentDay ? styles.highlighted : {}) }}>
                                      {slot ? (
                                        <>
                                          {slot.time}<br />
                                          {slot.subject}<br />
                                          {showTeacher && <span>{slot.teacher}<br /></span>}
                                          {showRoom && <span>{slot.room}</span>}
                                        </>
                                      ) : (
                                        'Not Planned'
                                      )}
                                    </td>
                                  );
                                })}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <div>
                        <div className='card-body'>
                          <div className="container">
                            <div className="row">
                              <div className="col-md-2">
                                <label className="custom_check mr-2 mb-0 d-inline-flex remember-me">
                                  {" "}
                                  Show Teacher
                                  <input 
                                    type="checkbox"
                                    name="radio"
                                    checked={showTeacher} 
                                    onChange={() => setShowTeacher(!showTeacher)}
                                  />
                                  <span className="checkmark" />
                                </label>
                              </div>
                              <div className="col-md-2">
                                <label className="custom_check mr-2 mb-0 d-inline-flex remember-me">
                                  {" "}
                                  Show Room
                                  <input 
                                    type="checkbox"
                                    name="radio"
                                    checked={showRoom} 
                                    onChange={() => setShowRoom(!showRoom)} 
                                  />
                                  <span className="checkmark" />
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
}


