import React, { useState } from "react";
import Spinner from "../Components/spinner";

export default function Fees() {
  const [showContent, setShowContent] = useState(false);

  const handleSpinnerComplete = () => {
    setShowContent(true);
  };
  return (
    <>
     {!showContent && <Spinner onComplete={handleSpinnerComplete} />}
     {showContent &&
      <div className="content container-fluid">
        <div className="col-md-12">
          <div className="card bg-white">
            <div className="card-header">
              <h5 className="card-title">Fees</h5>
            </div>
            <div className="card-body">
              <ul className="nav nav-tabs nav-tabs-bottom nav-justified">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    href="#bottom-justified-tab1"
                    data-bs-toggle="tab"
                  >
                    Paid
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="#bottom-justified-tab2"
                    data-bs-toggle="tab"
                  >
                    Un-Paid
                  </a>
                </li>
                {/* <li className="nav-item"><a className="nav-link" href="#bottom-justified-tab3" data-bs-toggle="tab">Messages</a></li> */}
              </ul>
              <div className="tab-content">
                <div
                  className="tab-pane show active"
                  id="bottom-justified-tab1"
                >
                  <div className="row">
          <div className="col-xl-12 d-flex">
            <div className="card flex-fill student-space comman-shadow">
              <div className="card-header d-flex align-items-center">
                {/* <h5 className="card-title">Fees</h5> */}
                <ul className="chart-list-out student-ellips">
                  {/* <li className="star-menus">
                    <a href={{ javascript: void 0 }}>
                      <i className="fas fa-ellipsis-v" />
                    </a>
                  </li> */}
                </ul>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table star-student table-hover table-center table-borderless table-striped">
                    <thead className="thead-light">
                      <tr>
                        <th>Sr. No.</th>
                        <th>Challan Number</th>
                        <th>Challan Type</th>
                        <th>Class</th>
                        <th>Amount</th>
                        <th>Installment</th>
                        <th>Due Date</th>
                        <th>Paid Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="text-nowrap">
                          <div>PRE2209</div>
                        </td>
                        <td>7057045670</td>
                        <td>Education</td>
                        <td>Part 1</td>
                        <td>Amount</td>
                        <td>Installment</td>
                        <td>21-01-2024</td>
                        <td>30-02-2024</td>
                      </tr>
                      <tr>
                        <td className="text-nowrap">
                          <div>PRE2209</div>
                        </td>
                        <td>7057045670</td>
                        <td>Education</td>
                        <td>Part 1</td>
                        <td>Amount</td>
                        <td>Installment</td>
                        <td>21-01-2024</td>
                        <td>30-02-2024</td>
                      </tr>
                      <tr>
                        <td className="text-nowrap">
                          <div>PRE2209</div>
                        </td>
                        <td>7057045670</td>
                        <td>Education</td>
                        <td>Part 1</td>
                        <td>Amount</td>
                        <td>Installment</td>
                        <td>21-01-2024</td>
                        <td>30-02-2024</td>
                      </tr>
                      <tr>
                        <td className="text-nowrap">
                          <div>PRE2209</div>
                        </td>
                        <td>7057045670</td>
                        <td>Education</td>
                        <td>Part 1</td>
                        <td>Amount</td>
                        <td>Installment</td>
                        <td>21-01-2024</td>
                        <td>30-02-2024</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
                </div>
                <div className="tab-pane" id="bottom-justified-tab2">
                  No Data Found
                </div>
                {/* <div className="tab-pane" id="bottom-justified-tab3">
          Tab content 3
        </div> */}
              </div>
            </div>
          </div>
        </div>

       
      </div>}
    </>
  );
}
