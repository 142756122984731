import React, { useState } from "react";
import Spinner from "../Components/spinner";

export default function AskQuestion() {
  const [selectTeacher, setSelectTeacher] = useState();
  const [question, setQuestion] = useState("");
  const [showContent, setShowContent] = useState(false);

  const handleSpinnerComplete = () => {
    setShowContent(true);
  };

  const teacherData = [
    { name: "Teacher1", id: "Teacher1" },
    { name: "Teacher2", id: "Teacher2" },
    { name: "Teacher3", id: "Teacher3" },
    { name: "Teacher4", id: "Teacher4" },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    // alert(''+selectTeacher+' '+' '+question+'');
  };

  return (
    <>
     {!showContent && <Spinner onComplete={handleSpinnerComplete} />}
     {showContent &&
      <div className="content container-fluid">
        <div className="col-md-12">
          <div className="card bg-white">
            <div className="card-header">
              <h5 className="card-title">Ask a Question</h5>
            </div>
            <div className="card-body">
              <div className="container">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-form-label col-md-2">
                      <label htmlFor="teacher">Select Teacher</label>
                    </div>
                    <div className="col-md-10">
                      <select
                        className="form-control form-select"
                        name="teacher"
                        id="teacher"
                        value={selectTeacher}
                        onChange={(e) => setSelectTeacher(e.target.value)}
                      >
                        <option value="">Select a teacher</option>
                        {teacherData.map((teacher) => (
                          <option key={teacher.id} value={teacher.id}>
                            {teacher.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="row my-5">
                    <div className="col-form-label col-md-2">
                      <label htmlFor="question">Enter Question</label>
                    </div>
                    <div className="col-md-10">
                      <textarea
                        className="form-control"
                        id="question"
                        value={question}
                        onChange={(e) => setQuestion(e.target.value)}
                      ></textarea>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <button type="submit" className="btn btn-primary">
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>}
    </>
  );
}
