import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import Lms from "../Controllers/Lms"
import Spinner from '../Components/spinner';


export default function Modules(props) {
  const styles = {
    th: {
      color: 'white',
      backgroundColor: '#001a70',
      //   textAlign: 'center',
    },
    td: {
      textAlign: 'center',
    },
  };

  // const { courseId } = useParams();
  const [modules, setModules] = useState([]);
  const { http } = Lms(); // Initialize Lms controller
  const token = JSON.parse(localStorage.getItem("token")); // Get the token once
  const [loading, setLoading] = useState(true);  // Loading state for the component
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;
  // console.log(state);

  const loadModules = () => {
    http.post("/getModules", {
      token,
      courseId: state.id,
    })
      .then(response => {
        if (response.status === 200) {
          // console.log('API response:', response.data);
          setModules(response.data.data);
        } else {
          console.error("Fetching categories failed:", response.data.message);
        }
      })
      .catch(error => {
        console.error("Error during fetching categories:", error.message);
        localStorage.clear();
        navigate('/login');
      })
      .finally(() => {
        setLoading(false);
      });
  }



  useEffect(() => {
    loadModules();
    if (!state) {
      navigate('/elearning');
  }
  }, [state, navigate]); // Empty dependency array ensures this runs only once
  
    if (!state) {
      return null; // Or return a loading spinner or message if desired
  }

  return (
    <>
      {loading ?
        <>
          <Spinner />
        </> :
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-sub-header">
                  <h3 className="page-title">{props.name} Modules</h3>
                  {/* <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/elearning">E-Learning</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/videocategories">Video Lectures</Link>
                  </li>
                  <li className="breadcrumb-item active">Video Categories</li>
                </ul> */}
                </div>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="col-xl-12 d-flex">
              <div className="card flex-fill student-space comman-shadow">
                <div className="card-body">
                  <div className="row my-4">
                    <div className="col-12">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th style={styles.th}>Index</th>
                            <th style={styles.th}>Title</th>
                            {props.name === "Video" ? (
                              <th style={styles.th}>Topics</th>
                            ) : (
                              <>
                                <th style={styles.th}>Questions</th>
                                <th style={styles.th}>Action</th>
                              </>

                            )}

                          </tr>
                        </thead>
                        <tbody>
                          {modules.map((module, index) => (

                            <tr key={module.id}>
                              <td>{index + 1}</td>
                              <td>{module.moduleename}</td>
                              {props.name === "Video" ? (
                                <td>
                                  <Link to={`/${props.link}`} state={{id: module.id, courseName: state.courseName}} className="btn"  style={{backgroundColor:"#e1251b", color:"white" }}>
                                    View Topics
                                  </Link>
                                </td>
                              ) : (
                                <>
                                  {/* <td>{module.questions}</td> */}
                                  <td>1</td>
                                  <td>
                                    <Link to={`/${props.link}`} state={{id: module.id}} className="btn btn-primary">
                                      View {props.name}
                                    </Link>
                                  </td>
                                </>
                              )}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
}
