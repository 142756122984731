import React, { useState } from 'react';
import Spinner from "../Components/spinner";

const styles = {
  th: {
    color: 'white',
    backgroundColor: '#001a70',
    textAlign: 'center',
  },
  td: {
    textAlign: 'center',
  },
};

// Sample material data in JSON format
const materialData = [
    {
      fileName: 'Ch 1 Introduction.pdf',
      fileType: 'PDF',
      downloadPage: 'https://example.com/materials/chapter1',
      date: '2017-10-1',
    },
    {
      fileName: 'Lecture 1 Slides.pptx',
      fileType: 'PPTX',
      downloadPage: 'https://example.com/materials/lecture1',
      date: '2017-10-1',
    },
    {
      fileName: 'Ch 2 Notes.docx',
      fileType: 'DOCX',
      downloadPage: 'https://example.com/materials/chapter2',
      date: '2017-10-1',
    },
    // ... data for other materials
  ];

export default function Materials() {
  const [showContent, setShowContent] = useState(false);

    const handleSpinnerComplete = () => {
      setShowContent(true);
    };
  return (
    <>
     {!showContent && <Spinner onComplete={handleSpinnerComplete} />}
     {showContent &&
      <div className="content container-fluid">
        <div className="col-md-12">
          <div className="card bg-white">
            <div className="card-header">
              <div className="row">
                <div className="col-md-10">
                  <h5 className="card-title">Materials</h5>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="table-responsive">
              <table className="table mb-0">
                <thead className="thead-light">
                    <tr>
                    <th style={styles.th}>Filename</th>
                    <th style={styles.th}>Type</th>
                    <th style={styles.th}>Date</th>
                    <th style={styles.th}>Download</th>
                    </tr>
                </thead>
                <tbody>
                    {materialData.map((material, index) => (
                    <tr key={index}>
                        <td style={styles.td}>{material.fileName}</td>
                        <td style={styles.td}>{material.fileType}</td>
                        <td style={styles.td}>{material.date}</td>
                        <td style={styles.td}>
                        <a href={material.downloadPage} target="_blank" rel="noreferrer">
                            <button className="btn btn-danger">Download</button>
                        </a>
                        </td>
                    </tr>
                    ))}
                </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>}
    </>
  );
}
