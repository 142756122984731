import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Lms from "../Controllers/Lms";
import Spinner from "../Components/spinner";
import "katex/dist/katex.min.css";
import { BlockMath } from "react-katex";

const MCQs = () => {
  const [answeredQuestions, setAnsweredQuestions] = useState({});
  const [questions, setQuestion] = useState([]);
  const { http } = Lms(); // Initialize Lms controller
  const token = JSON.parse(localStorage.getItem("token")); // Get the token once
  const [loading, setLoading] = useState(true); // Loading state for the component
  const [error, setError] = useState(null); // Error state
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;

  const handleOptionClick = (questionId, optionKey) => {
    setAnsweredQuestions((prev) => ({
      ...prev,
      [questionId]: {
        selectedOption: optionKey,
        isAnswered: true,
      },
    }));
  };

  // Map answer number to option key
  const answerMapping = {
    1: "OptionOne",
    2: "OptionTwo",
    3: "OptionThree",
    4: "OptionFour",
  };

  const loadQuestions = () => {
    http
      .post("/getQuestions", {
        token,
        questionType: "MCQs",
        moduleId: state.id,
      })
      .then((response) => {
        if (response.status === 200) {
          console.log("API response:", response.data);
          // Transform questions to include answerKey
          const transformedQuestions = response.data.data.map((question) => ({
            ...question,
            answerKey: answerMapping[question.Answer], // Map the answer to option key
          }));
          setQuestion(transformedQuestions);
        } else {
          console.error("Fetching questions failed:", response.data.message);
          setError(response.data.message);
        }
      })
      .catch((error) => {
        console.error("Error during fetching questions:", error.message);
        setError(error.message);
        localStorage.clear();
        navigate("/login");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!state) {
      navigate("/elearning");
    } else {
      loadQuestions();
    }
  }, [state, navigate]);

  if (loading) return <Spinner />; // Replace with your spinner component or loading indicator
  if (error) return <div>Error: {error}</div>;

  return (
    <>
      {loading ?
        <>
          <Spinner />
        </> :
    <div className="content container-fluid">
      <div className="col-md-12">
        <div className="card bg-white">
          <div className="card-header">
            <h5 className="card-title">MCQs</h5>
          </div>
          <div className="card-body">
            {questions.map((question, index) => {
              const answered = answeredQuestions[question.Id] || {};
              const { selectedOption, isAnswered } = answered;
              const isCorrect = selectedOption === question.answerKey; // Compare with answerKey

              const options = [
                { key: "OptionOne", label: "OptionOne" },
                { key: "OptionTwo", label: "OptionTwo" },
                { key: "OptionThree", label: "OptionThree" },
                { key: "OptionFour", label: "OptionFour" },
              ];

              return (
                <div key={question.Id} style={{ marginBottom: "20px" }}>
                  <h3>Question {index + 1}</h3>
                  <div
                    dangerouslySetInnerHTML={{ __html: question.Title }}
                  ></div>

                  <div>
                    {options.map(({ key }) => {
                      const optionValue = question[key];
                      const isOptionSelected = selectedOption === key;
                      const isOptionCorrect = isOptionSelected && isCorrect;
                      const optionStyle = isAnswered
                        ? isOptionCorrect
                          ? { backgroundColor: "lightgreen" }
                          : isOptionSelected
                          ? { backgroundColor: "salmon" }
                          : {}
                        : {};

                      return (
                        <div
                          key={key}
                          onClick={() => handleOptionClick(question.Id, key)}
                          style={{
                            backgroundColor: "#e1e1e1",
                            ...optionStyle,
                            cursor: "pointer",
                            padding: "10px",
                            marginBottom: "5px",
                          }}
                          className="card-body"
                          dangerouslySetInnerHTML={{ __html: optionValue }}
                        ></div>
                      );
                    })}
                  </div>

                  {isAnswered && !isCorrect && (
                    <div
                      style={{ color: "red", marginTop: "10px" }}
                      dangerouslySetInnerHTML={{ __html: question.Solutions }}
                    ></div>
                  )}

                  <p>{question.Instructions}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
       }
    </>
  );
};

export default MCQs;
