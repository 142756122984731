import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Lms from "../Controllers/Lms";
import Spinner from "../Components/spinner";
import "katex/dist/katex.min.css";
import { BlockMath } from "react-katex";

export default function () {
  const [questions, setQuestion] = useState([]);
  const { http } = Lms(); // Initialize Lms controller
  const token = JSON.parse(localStorage.getItem("token")); // Get the token once
  const [loading, setLoading] = useState(true); // Loading state for the component
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;
  // console.log(state);
  const loadQuestions = () => {
    http
      .post("/getQuestions", {
        token,
        questionType: "Short Questions",
        moduleId: state.id,
      })
      .then((response) => {
        if (response.status === 200) {
          console.log("API response:", response.data);
          setQuestion(response.data.data);
        } else {
          console.error("Fetching categories failed:", response.data.message);
        }
      })
      .catch((error) => {
        console.error("Error during fetching categories:", error.message);
        localStorage.clear();
        navigate("/login");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    loadQuestions();
    if (!state) {
      navigate("/elearning");
    }
  }, [state, navigate]);

  if (!state) {
    return null; // Or return a loading spinner or message if desired
  }

  return (
    <>
      {loading ?
        <>
          <Spinner />
        </> :<>
      <div className="content container-fluid">
        <div className="col-md-12">
          <div className="card bg-white">
            <div className="card-header">
              <h5 className="card-title">Short Question</h5>
            </div>
          </div>
        </div>
      </div>
      <div className="content container-fluid">
        <div className="col-md-12">
          {questions.map((question, index) => (
            <div className="card mb-1 my-3" key={index}>
              <div className="card-header border-0" id="headingFive">
                <h5 className="accordion-faq m-0 position-relative">
                  {/* <a className="custom-accordion-title text-reset d-block collapsed" data-bs-toggle="collapse" href="#collapseFive1" aria-expanded="false" aria-controls="collapseFive">
                    <span>Q.</span>  <div dangerouslySetInnerHTML={{ __html: question.Title }} /> <i className="mdi mdi-chevron-down accordion-arrow" />
                  </a> */}
                  <a
                    className="custom-accordion-title text-reset d-block collapsed d-flex"
                    data-bs-toggle="collapse"
                    href={`#collapse${index}`}
                    aria-expanded="false"
                    aria-controls={`#collapse${index}`}
                  >
                    <span>Q.{index + 1}</span>&nbsp;&nbsp;
                    {/* <BlockMath math={question.Title} /> */}
                    <div dangerouslySetInnerHTML={{ __html: question.Title }} />
                    <i className="mdi mdi-chevron-down accordion-arrow" />
                  </a>
                </h5>
              </div>
              <div
                id={`collapse${index}`}
                className="collapse"
                aria-labelledby="headingFive"
                data-bs-parent="#custom-accordion-one"
                style={{}}
              >
                <div className="card-body">
                  {/* <BlockMath math={question.Title} /> */}
                  <div dangerouslySetInnerHTML={{ __html: question.Answer }} />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      </>   }
      </>
  );
}
