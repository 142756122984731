import React, { useState} from "react";
import { Link } from "react-router-dom";
import Spinner from "../Components/spinner";

export default function Elearning() {
  const [showContent, setShowContent] = useState(false);

  const handleSpinnerComplete = () => {
    setShowContent(true);
  };
  
  const eData = [
    {
      title: "Video Lectures",
      img: "assets/img/profiles/lmsv.png",
      link: "/videocategories",
    },
    {
      title: "Long Question",
      img: "assets/img/profiles/lmsl.png",
      link: "/longquestioncategories",
    },
    {
      title: "Short Question",
      img: "assets/img/profiles/lmss.png",
      link: "/shortquestioncategories",
    },
    {
      title: "MCQs",
      img: "assets/img/profiles/lmsm.png",
      link: "/mcqscategories",
    },
  ];
  return (
    <>
    {!showContent && <Spinner onComplete={handleSpinnerComplete} />}
    {showContent &&  <div className="content container-fluid">
      <div className="page-header">
        <div className="row">
          <div className="col-sm-12">
            <div className="page-sub-header">
              <h3 className="page-title">E-Learning</h3>
              {/* <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active">E-Learning</li>
              </ul> */}
            </div>
          </div>
        </div>
      </div>
      <div className="row my-4">
        {eData.map((item, index) => (
          <div className="col-md-4" key={index}>
            <div className="card bg-comman w-100">
              <Link to={item.link}>
                <div className="card-body">
                  <div className="container" align="center">
                    <div className="avatar avatar-xxl">
                      <img
                        className="avatar-img"
                        alt="User"
                        src={item.img}
                      />
                    </div>
                    <h3 className="my-2">{item.title}</h3>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>}
    </>
  );
}
