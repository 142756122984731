import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../config';

export default function Auth() {
    const navigate = useNavigate();

    const getToken = () => {
        // const tokenString = sessionStorage.getItem('token');
        const tokenString = localStorage.getItem('token');
        const userToken = JSON.parse(tokenString);
        return userToken;
    }

    const getUser = () => {
        // const userString = sessionStorage.getItem('user');
        const userString = localStorage.getItem('user');
        const userDetail = JSON.parse(userString);
        return userDetail;
    }

    const [token, setToken] = useState(getToken());
    const [user, setUser] = useState(getUser());
   

    const saveToken = async (user, token, institute, program, sclass) => {
        try {
            // sessionStorage.setItem('token', JSON.stringify(token));
            // sessionStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('token', JSON.stringify(token));
            localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('institute', JSON.stringify(institute));
            localStorage.setItem('program', JSON.stringify(program));
            localStorage.setItem('sclass', JSON.stringify(sclass));
            setToken(token);
            setUser(user);
            console.log('Saving token');
            navigate('/');
        } catch (error) {
            console.error('Error saving token:', error);
        }
    }
    

    const logout = () => {
        // sessionStorage.clear();
        localStorage.clear();
        navigate('/login');
    }

    const http = axios.create({
        baseURL: API_BASE_URL,
        headers: {
            "Content-type": "application/json",
            // "Authorization": token ? `Bearer ${token}` : ''
        }
    });

    return {
        setToken: saveToken,
        token,
        user,
        getToken,
        http,
        logout
    }
}
