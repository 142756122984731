import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Lms from "../Controllers/Lms"
import Spinner from '../Components/spinner';
import { IMG_BASE_URL } from '../config';


export default function Categories(props) {
  const { http } = Lms(); // Initialize Lms controller
  const [categories, setCategories] = useState([]);
  const token = JSON.parse(localStorage.getItem("token")); // Get the token once
  const [loading, setLoading] = useState(true);  // Loading state for the component
  const navigate = useNavigate();

  const loadCategories = () => {
    http.post("/getCategories", { token })
      .then(response => {
        if (response.status === 200) {
          // console.log('API response:', response.data);
          setCategories(response.data.data);
        } else {
          console.error("Fetching categories failed:", response.data.message);
        }
      })
      .catch(error => {
        console.error("Error during fetching categories:", error.message);
        localStorage.clear();
        navigate('/login');
        
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    loadCategories();
  }, []); // Empty dependency array ensures this runs only once

  return (
    <>
      {loading ? 
        <>
       <Spinner />
        </> : 
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-sub-header">
                  <h3 className="page-title">{props.name} Categories</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="row my-4">
            {categories.map((item, index) => (
              <div className="col-md-4" key={index}>
                <div className="card bg-comman w-100">
                  <Link to={`/${props.link}courses`} state={item.id}>
                    <div className="card-body">
                      <div className="container" align="center">
                        <div className="avatar avatar-xxl">
                          <img
                            className="avatar-img"
                            alt={item.categoryname}
                            src={`${IMG_BASE_URL}/${item.imgurl}`}
                          />
                        </div>
                        <h3 className="my-2">{item.categoryname}</h3>
                        {/* <p>{item.description ? item.description : "No description available"}</p> */}
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      }
    </>
  )
}
