import React, { useState, useEffect } from "react";
import Spinner from "../Components/spinner";
import { useNavigate } from "react-router-dom";
import Lms from "../Controllers/Lms";

export default function PastPapers() {
  const [courses, setCourses] = useState([]);
  const [boards, setBoards] = useState([]);
  const [year, setYear] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState("");
  const [selectedBoard, setSelectedBoard] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [boardOptions, setBoardOptions] = useState(false);
  const [yearOptions, setYearOptions] = useState(false);
  const [pastPapers, setPastPapers] = useState();
  const [paperFound, setPaperfound] = useState(true);
  const [pdfUrl, setPdfUrl] = useState(""); // State for PDF URL
  const { http } = Lms(); // Initialize Lms controller
  const token = JSON.parse(localStorage.getItem("token")); // Get the token once
  const [loading, setLoading] = useState(true); // Loading state for the component
  const navigate = useNavigate();

  const loadCourses = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    const programId = user.programdetailid;
    // console.log(programId);
    http
      .post("/getProgramCourses", {
        token,
        programId: programId,
      })
      .then((response) => {
        if (response.status === 200) {
          // console.log("API response:", response.data);
          // Flatten the nested arrays
          const flattenedCourses = response.data.data.flat();
          setCourses(flattenedCourses);
        } else {
          console.error("Fetching categories failed:", response.data.message);
        }
      })
      .catch((error) => {
        console.error("Error during fetching categories:", error.message);
        localStorage.clear();
        navigate("/login");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const loadBoard = () => {
    http
      .post("/getBoards", {
        token,
      })
      .then((response) => {
        if (response.status === 200) {
          setBoardOptions(true);
          // console.log("API response:", response.data);
          // Flatten the nested arrays
          const flattenedBoards = response.data.data.flat();
          setBoards(flattenedBoards);
        } else {
          console.error("Fetching categories failed:", response.data.message);
        }
      })
      .catch((error) => {
        console.error("Error during fetching categories:", error.message);
        localStorage.clear();
        navigate("/login");
      })
      .finally(() => {
        // setLoading(false);
      });
  };

  const loadBoardYear = () => {
    http
      .post("/getBoardyear", {
        token,
      })
      .then((response) => {
        if (response.status === 200) {
          setYearOptions(true);
          // console.log("API response:", response.data);
          // Flatten the nested arrays
          const flattenedYear = response.data.data.flat();
          setYear(flattenedYear);
        } else {
          console.error("Fetching categories failed:", response.data.message);
        }
      })
      .catch((error) => {
        console.error("Error during fetching categories:", error.message);
        localStorage.clear();
        navigate("/login");
      })
      .finally(() => {
        // setLoading(false);
      });
  };

  const handlePdfDisplay = () => {
    // Construct PDF URL based on selected options
    console.log(selectedCourse);
    console.log(selectedBoard);
    console.log(selectedYear);
    http
    .post("/getPastpapers", {
      courseId: selectedCourse,
      boardId: selectedBoard,
      boardYearId: 1,
      token,
    })
    .then((response) => {
      if (response.status === 200) {
        setPastPapers(response.data.data)
        try{
          const url = pastPapers.pfile;
          console.log(url) // Adjust the URL pattern as needed
          setPdfUrl(url);
        } catch (e) {
          const url = '';
          setPdfUrl(url);
          setPaperfound(false);
        }
        // const url = 'http://127.0.0.1:8001/assets/upload/2022-49-51-Past-Paper-9-Eng-lahore.pdf'; // Adjust the URL pattern as needed
        // const url = response.data.data[0].pfile; // Adjust the URL pattern as needed
        // setPdfUrl(url);
        console.log("API response:", response.data);
      } else {
        console.error("Fetching categories failed:", response.data.message);
      }
    })
    .catch((error) => {
      console.error("Error during fetching categories:", error.message);
      localStorage.clear();
      navigate("/login");
    })
    .finally(() => {
      // setLoading(false);
    });
      
  };

  useEffect(() => {
    loadCourses();
  }, []);



  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <div className="content container-fluid">
          <div className="col-md-12">
            <div className="card bg-white">
              <div className="card-header">
                <h5 className="card-title">Past Papers</h5>
              </div>
              <div className="card-body">
                <div className="container">
                  <div className="row">
                    <div className="col-form-label col-md-2">
                      <label htmlFor="Course">Select Courses</label>
                    </div>
                    <div className="col-md-10">
                      <select
                        className="form-control form-select"
                        name="Course"
                        id="Course"
                        value={selectedCourse}
                        onChange={(e) => {
                          setSelectedCourse(e.target.value);
                          loadBoard(); // Fetch boards based on selected course
                        }}
                      >
                        <option value="">Select a course</option>
                        {courses.map((course) => (
                          <option key={course.CourseId} value={course.CourseId}>
                            {course.CourseName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  {boardOptions && (
                    <div className="row my-3">
                      <div className="col-form-label col-md-2">
                        <label htmlFor="board">Select Boards</label>
                      </div>
                      <div className="col-md-10">
                        <select
                          className="form-control form-select"
                          name="board"
                          id="board"
                          value={selectedBoard}
                          onChange={(e) => {
                            setSelectedBoard(e.target.value);
                            loadBoardYear(); // Fetch boards based on selected course
                          }}
                        >
                          <option value="">Select a Board</option>
                          {boards.map((board) => (
                            <option key={board.id} value={board.id}>
                              {board.boardname}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  )}
                  {yearOptions && (
                    <div className="row my-3">
                      <div className="col-form-label col-md-2">
                        <label htmlFor="board">Select Year</label>
                      </div>
                      <div className="col-md-10">
                        <select
                          className="form-control form-select"
                          name="board"
                          id="board"
                          value={selectedYear}
                          onChange={(e) => {
                            setSelectedYear(e.target.value);
                            handlePdfDisplay(); // Fetch PDF URL based on selected options
                            // Fetch boards based on selected course
                          }}
                        >
                          <option value="">Select a Board</option>
                          {year.map((years) => (
                            <option key={years.id} value={years.id}>
                              {years.boardyear}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  )}
                  {pdfUrl && (
                    <div className="row my-3">
                      <iframe
                        src={pdfUrl}
                        width="100%"
                        height="600px"
                        title="Past Papers"
                      />
                    </div>
                  )}
                  {!paperFound && (
                  <div className="container">
                    No Paper Found
                  </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
