import React from 'react'
import { useEffect } from 'react'
import Auth from '../Controllers/Auth'

export default function Logout() {
    const { logout } = Auth();
    useEffect(() =>
        {
            logout();
        }
    )
}
