import React, { useState, useEffect } from "react";
import  Auth from "../Controllers/Auth";
import Spinner from "../Components/spinner";

export default function Profile() {
  const [showContent, setShowContent] = useState(false);

  const handleSpinnerComplete = () => {
    setShowContent(true);
  };
    const { user } = Auth();

    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [phone, setPhone] = useState();
    const [address, setAddress] = useState();
    const [program, setProgram] = useState();
    const [section, setSection] = useState();
    const [mobile, setMobile] = useState();
    const [rollNo, setRollNo] = useState();
    const [dob, setDob] = useState();
    const [fatherName, setFatherName] = useState();
    const [fatherNic, setFatherNic] = useState();
    const [fatherPhone, setFatherPhone] = useState();



    useEffect(() =>
        {
            const firstName = user.FirstName;
            const lastName = user.LastName;
            const name = firstName + " " + lastName;
            const programFromSession = localStorage.getItem('program');
            const classFromSession = localStorage.getItem('sclass');
            const program = JSON.parse(programFromSession);
            const section = JSON.parse(classFromSession);
            setSection(section);
            setProgram(program);
            setName(name);
            setEmail(user.Email);
            setPhone(user.ContactNo);
            setDob(user.DateOfBirth);
            setEmail(user.EmailAddress);
            setAddress(user.Address);
            setRollNo(user.RegNo);
            setFatherName(user.fathername);
            setFatherNic(user.fathernic);
            setFatherPhone(user.fatherphone);

        }
    )

  return (
    <>
     {!showContent && <Spinner onComplete={handleSpinnerComplete} />}
     {showContent &&
    <div className="content container-fluid">
      {/* <div className="page-header">
        <div className="row">
          <div className="col">
            <h3 className="page-title">Profile</h3>
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="index.html">Dashboard</a>
              </li>
              <li className="breadcrumb-item active">Profile</li>
            </ul>
          </div>
        </div>
      </div> */}
      <div className="row">
        <div className="col-md-12">
          <div className="profile-header">
            <div className="row align-items-center">
              <div className="col-auto profile-image">
                <a href="#">
                  <img
                    className="rounded-circle"
                    alt="User Image"
                    src="assets/img/profiles/untitled.png"
                  />
                </a>
              </div>
              <div className="col ms-md-n2 profile-user-info">
                <h4 className="user-name mb-0"> {name  ? <>{name}</> : <>Loading...</>}</h4>
                <h6 className="text-muted"> {program  ? <>{program}</> : <>Loading...</>}</h6>
              </div>
             
            </div>
          </div>
       
          <div className="tab-content profile-tab-cont">
            <div className="tab-pane fade show active" id="per_details_tab">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title d-flex justify-content-between">
                        <span>Personal Details</span>
              
                      </h5>
                      <div className="row">
                        <p className="col-sm-3 text-muted text-sm-end mb-0 mb-sm-3">
                          Name
                        </p>
                        <p className="col-sm-9"> {name  ? <>{name}</> : <>Loading...</>}</p>
                      </div>
                      <div className="row">
                        <p className="col-sm-3 text-muted text-sm-end mb-0 mb-sm-3">
                          Class
                        </p>
                        <p className="col-sm-9">{program  ? <>{program}</> : <>Loading...</>}</p>
                      </div>
                      <div className="row">
                        <p className="col-sm-3 text-muted text-sm-end mb-0 mb-sm-3">
                          Section
                        </p>
                        <p className="col-sm-9">{section  ? <>{section}</> : <>Loading...</>}</p>
                      </div>
                      <div className="row">
                        <p className="col-sm-3 text-muted text-sm-end mb-0 mb-sm-3">
                          Mobile
                        </p>
                        <p className="col-sm-9">{phone  ? <>{phone}</> : <>Loading...</>}</p>
                      </div>
                      <div className="row">
                        <p className="col-sm-3 text-muted text-sm-end mb-0 mb-sm-3">
                          Email
                        </p>
                        <p className="col-sm-9">{email  ? <>{email}</> : <>Loading...</>}</p>
                      </div>
                      <div className="row">
                        <p className="col-sm-3 text-muted text-sm-end mb-0 mb-sm-3">
                          Date Of Birth
                        </p>
                        <p className="col-sm-9">{dob  ? <>{dob}</> : <>Loading...</>}</p>
                      </div>
                     
                      <div className="row">
                        <p className="col-sm-3 text-muted text-sm-end mb-0 mb-sm-3">
                          Address
                        </p>
                        <p className="col-sm-9">{address  ? <>{address}</> : <>Loading...</>}</p>
                      </div>
                      <div className="row">
                        <p className="col-sm-3 text-muted text-sm-end mb-0 mb-sm-3">
                          Roll No
                        </p>
                        <p className="col-sm-9">{rollNo   ? <>{rollNo}</> : <>Loading...</>}</p>
                      </div>
                      <div className="row">
                        <p className="col-sm-3 text-muted text-sm-end mb-0 mb-sm-3">
                          Father Name
                        </p>
                        <p className="col-sm-9">{fatherName  ? <>{fatherName}</> : <>Loading...</>}</p>
                      </div>
                      <div className="row">
                        <p className="col-sm-3 text-muted text-sm-end mb-0 mb-sm-3">
                          Father Nic
                        </p>
                        <p className="col-sm-9">{fatherNic   ? <>{fatherNic}</> : <>Loading...</>}</p>
                      </div>
                      <div className="row">
                        <p className="col-sm-3 text-muted text-sm-end mb-0 mb-sm-3">
                          Father Phone
                        </p>
                        <p className="col-sm-9">{fatherPhone  ? <>{fatherPhone}</> : <>Loading...</>}</p>
                      </div>
                      
                    </div>
                  </div>
                </div>
           
              </div>
            </div>
     
          </div>
        </div>
      </div>
    </div>}
    </>
  );
}
