import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Spinner from "../Components/spinner";


export default function ViewVideo() {
    const location = useLocation();
    const navigate = useNavigate();
    const { state } = location;
    // console.log(state);
    const [showContent, setShowContent] = useState(false);

    const handleSpinnerComplete = () => {
      setShowContent(true);
    };
    useEffect(() => {
        if (!state) {
            navigate('/elearning');
        }
    }, [state, navigate]);

    if (!state) {
        return null; // Or return a loading spinner or message if desired
    }

    // console.log(state);
    return (
        <>
        {!showContent && <Spinner onComplete={handleSpinnerComplete} />}
        {showContent &&
        <div className="content container-fluid">
            <div className="col-md-12">
                <div className="card bg-white">
                    <div className="card-header">
                        <div className="row">
                            <div className="col-md-10">
                                <button className='btn' onClick={() => navigate(-1)} style={{backgroundColor: "#e1251b", color:"white"}}>Back</button> 
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="col-xl-12 d-flex">
                            <div className="card flex-fill student-space comman-shadow">
                                <div className="card-body">
                                    <div className="card-body">
                                        <div className="container" >
                                                <h3 className="header-title"  style={{color:"#001a70"}}><b>{state.courseName}</b></h3>
                                        <h4 className="header-title"  style={{color:"#001a70"}}><b>{state.topic.topicname}</b></h4>
                                        </div>
                                    
                                        <br />
                                        {/* <p className="sub-header">Use class <code>.ratio-16x9</code></p> */}
                                        <div className="container">
                                              <div className="ratio ratio-16x9">
                                            <iframe src={`https://www.youtube.com/embed/${state.topic.videourl}`} />
                                            </div>
                                        </div>
                                      
                                    </div>
                                </div>
                                <div className="container" style={{color:"#001a70"}}>
                                    <h2 style={{color:"#001a70"}}>Description</h2>
                                    <p>{state.topic.description}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>}
        </>
    );
}
