import React, { Component, useState } from 'react'
import Spinner from "../Components/spinner";
export default function ExamResults() {
  const [showContent, setShowContent] = useState(false);

    const handleSpinnerComplete = () => {
      setShowContent(true);
    };
  return (
    <>
     {!showContent && <Spinner onComplete={handleSpinnerComplete} />}
     {showContent &&
    <div className="content container-fluid">
      <div className="col-md-12">
        <div className="card bg-white">
          <div className="card-header">
            <h5 className="card-title">Exam Results</h5>
          </div>
          <div className="card-body"></div>
        </div>
      </div>
    </div>}
  </>
)
}

