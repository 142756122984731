import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import React, { useEffect, useState } from 'react';
import MainContainer from './Components/MainContainer';
import Login from './Pages/Login';
import Logout from './Pages/Logout';


function App() {
  // const token1 = sessionStorage.getItem('token');
  // const token1 = localStorage.getItem('token');
  return (
    <div className="app">
      <Routes>
        <Route path="/login" element={<Login />} />
        {/* <Route path="/*" element={token1 ? <MainContainer /> : <Navigate to="/login" replace />} /> */}
        <Route path="/*" element={<MainContainer /> } />
        <Route path="/logout" element={<Logout />} />
      </Routes>
    </div>
  );
}

export default App;
