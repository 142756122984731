import React, { useState, useEffect } from "react";
// import { BrowserRouter as Router , useNavigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Auth from "../Controllers/Auth";

export default function Login() {

  // Session management start

  const navigate = useNavigate();
  useEffect(() => {
    // const token1 = sessionStorage.getItem('token');
    const token1 = localStorage.getItem('token');
    if (token1) {
      // console.log('logged in');
      navigate('/');
    } else {
      // console.log('not logged in');
    }
    document.title = "Login | COWEB";
  }, [navigate]);

  // Session management End

  const { http, setToken } = Auth();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   try {
  //     const response = await http.post("/login", {
  //       UserName: username,
  //       UserPassword: password,
  //     });

  //     if (response.status === 200) {
  //       const { profile, token, institute, program, sclass } = response.data;
  //       setToken(profile, token, institute, program, sclass); // Adjust setToken function based on your requirement
  //     } else {
  //       console.error("Login failed:", response.data.message);
  //     }
  //   } catch (error) {
  //     console.error("Error during login:", error.message);
  //   }
  // };
  const handleSubmit = (e) => {
    e.preventDefault();
      
    http.post("/login", {
        UserName: username,
        UserPassword: password,
    })
    .then(response => {
        if (response.status === 200) {
            const { profile, token, institute, program, sclass } = response.data;
            setToken(profile, token, institute, program, sclass); // Adjust setToken function based on your requirement
        } else {
            console.error("Login failed:", response.data.message);
        }
    })
    .catch(error => {
        console.error("Error during login:", error.message);
    });
};

  return (
    <div>
      <div className="main-wrapper login-body">
        <div className="login-wrapper">
          <div className="container">
            <div className="loginbox">
              <div className="login-left">
                <img
                  className="img-fluid"
                  src="assets/img/login.png"
                  alt="Logo"
                />
              </div>
              <div className="login-right">
                <div className="login-right-wrap">
                  <div className="container">
                    <img className="img-fluid" src="assets/img/logo.png" alt="" />
                  </div>
                  <br />
                  {/* <h1 style={{ textAlign: "center", color: "#001a70"}}>Welcome</h1> */}
                  {/* <p className="account-subtitle">
                    Need an account? <a href="register.html">Sign Up</a>
                  </p> */}
                  <h2>Sign in</h2>
                  <form onSubmit={handleSubmit}>
                    <div className="form-group">
                      <label>
                        Username <span className="login-danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                      />
                      <span className="profile-views">
                        <i className="fas fa-user-circle" />
                      </span>
                    </div>
                    <div className="form-group">
                      <label>
                        Password <span className="login-danger">*</span>
                      </label>
                      <input
                        className="form-control pass-input"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                      <span className="profile-views feather-eye toggle-password" />
                    </div>
                    <div className="forgotpass">
                      <div className="remember-me">
                        <label className="custom_check mr-2 mb-0 d-inline-flex remember-me">
                          {" "}
                          Remember me
                          <input type="checkbox" name="radio" />
                          <span className="checkmark" />
                        </label>
                      </div>
                      <a href="forgot-password.html">Forgot Password?</a>
                    </div>
                    <div className="form-group">
                      <button
                        className="btn btn-primary btn-block"
                        type="submit"
                      >
                        Login
                      </button>
                    </div>
                  </form>
                  {/* <div className="login-or">
                    <span className="or-line" />
                    <span className="span-or">or</span>
                  </div>
                  <div className="social-login">
                    <a href="/">
                      <i className="fab fa-google-plus-g" />
                    </a>
                    <a href="/">
                      <i className="fab fa-facebook-f" />
                    </a>
                    <a href="/">
                      <i className="fab fa-twitter" />
                    </a>
                    <a href="/">
                      <i className="fab fa-linkedin-in" />
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
