import React, { useState, useEffect } from 'react';

export default function Spinner({ onComplete }) {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
      if (onComplete) {
        onComplete();
      }
    }, 500); // 2000 milliseconds = 2 seconds

    return () => clearTimeout(timer); // Clean up the timer if the component unmounts
  }, [onComplete]);

  if (!visible) {
    return null;
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <table style={{ textAlign: 'center' }}>
        <tbody>

        
        <tr height="100%">
          <td height="100%" width="100%" valign="middle" align="center">
            {/* <div className="spinner-grow text-secondary" role="status" style={{ width: '100px', height: '100px' }}></div> */}
            <div className="spinner-grow text-primary m-2" role="status" style={{ width: '100px', height: '100px' }}></div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  );
}

